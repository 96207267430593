/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useRef} from "react";
import axios from "axios"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import {FeedTerms} from "../Popup";
// import { SwalError } from "../Popup";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
import {Tooltip,Overlay} from "react-bootstrap";
export const CcmodeRequest = (props) => {
    const [feedTermsOpen,setFeedTermsOpen] = useState(false)
    const [selectcheckbox,setSelectCheckbox] = useState(false)
    const [showerror,setErrorShow] = useState(false)
    const overlyTarget = useRef(null);
    const feedTermsfn = () => {
        setSelectCheckbox(!selectcheckbox)
        setFeedTermsOpen(true)
    }
    const RequestForCCMode = () => {
        if(!selectcheckbox){
            setErrorShow(true)
            setTimeout(() => {
            setErrorShow(false)
            },3000)
        }
        else{
            // console.log(finalData,"finalData")
            axios({
                method: "get",
                url: `${NODE_API_URL}/AUS/request-for-ccmode`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                }
            }).then(res => {
                if(res && res.data){
                // console.log(res.data,"RESPONSEDATA")
                window.location.reload(true)
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
    }
    return (
        <>
            {/* <SwalError show={showerror} onClose={() => setErrorShow(false)} onHide={() => setErrorShow(false)} message={"Please Read and Agree our Terms and Condition!"}/> */}
            <>
                <Overlay target={overlyTarget.current} show={showerror} placement="top">
                    {props => (
                        <Tooltip id="overlay-example" {...props}>
                            Please check this box if you want to proceed.
                        </Tooltip>
                    )}
                </Overlay>
            </>
            <div className="row align-content-start position-relative" style={{minHeight: "74vh"}}>
                <div className="col-12">
                   <h3>{props.language.CCMODE}</h3>
                   <p className="text-muted">{props.language.CCMODE_REQ_PARAGRAPH1}</p>
                   <p className="text-muted"> {props.language.CCMODE_REQ_PARAGRAPH2}</p>
                </div>
                <div className="col-12">
                    <div className="row feed-opt-box" id="title">
                        <div className="col-xl-3 col-lg-5 col-md-6 col-sm-6 py-3">
                            <div className="plugin-api-option-box">
                                <SVG  width="40" src={toAbsoluteUrl("/media/svg/icons/Custom/ccmode_page_icon.svg")} />
                                {/* <img className="api-icon1" width="40" src="<?= base_url() ?>assets/front/images/ccmode_page_icon.svg" /> */}
                                <p className="h5 bold mt-3">{props.language.CCMODE_SHORT}</p>
                                <p className="small text-muted">{props.language.CCOMDE_REQ_PARAGRAPH3}</p>
                                {/* <form id="btnSubmitform" name="api_request" action="<?= base_url('white-label-request'); ?>" method="post" role="form" data-toggle="validator"> */}
                                    <div className="row m-0 mb-2" id="enable_api">
                                        <div className="col-12 p-0">
                                            <div className="custom-control custom-checkbox small" >
                                                <input type="checkbox" className="custom-control-input acceptterms" ref={overlyTarget} onClick={() => feedTermsfn()} id="acceptterms" required />
                                                <input type="hidden" name="feed_type" value="API" />
                                                <label className="custom-control-label text-black-50 cursor-pointer" htmlFor="acceptterms" >{props.language.CCOMDE_REQ_PARAGRAPH4}</label>
                                            </div> 
                                            <button type="submit" name="name" id="btnSubmit" className="mt-2 btn btn-primary small px-3 py-2" onClick={() => RequestForCCMode()}>{props.language.CCMODE_REQ_REQUEST}</button>
                                        </div>
                                    </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
                <img src={toAbsoluteUrl("/media/General/ccmode_request.png")} alt="ccmode" className="img-fluid" width="50%" style={{ position: "absolute", bottom: "0", right: "0"}}/>
                <FeedTerms show={feedTermsOpen} onHide={() => setFeedTermsOpen(false)} language={props.language} overlyTarget={overlyTarget} setSelectCheckbox={setSelectCheckbox}/>
            </div>
        </>
    );
};

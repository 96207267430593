import React,{useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import { at } from 'lodash';
import { useFormik,FormikProvider } from "formik";
import PhoneInputFieldFull from "../../Popup/PhoneInputFieldFull";
import * as Yup from "yup";
import axios from 'axios';
import Cookies from "js-cookie"
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import { Backdrop,CircularProgress,FormControl,InputLabel,makeStyles, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
export const AddEditShipping = (props) => {
    // console.log(props.data)

    const classes = useStyles();    
    const [initialValues,setInitialValues] = useState({})
    const [openLoader,setOpenLoader] = useState(false)
    const [formKey, setFormkKey] = useState(2)
    useEffect(() => {
       if(props.data){
        setInitialValues({
            user_id:props.data.id,
            company_name_official:props.data.company_name_official || '',
            shipping_address_1: props.editShipping.shipping_address_1 || '',
            shipping_address_2: props.editShipping.shipping_address_2 || '',
            shipping_address_3: props.editShipping.shipping_address_3 || '',
            shipping_city: props.editShipping.shipping_city || '',
            country: props.data.country||'',
            shipping_state: props.editShipping.shipping_state || '',
            shipping_zip_code: props.editShipping.shipping_zip_code || '',
            shipping_email: props.editShipping.shipping_email || '',
            shipping_phone: props.editShipping.shipping_phone || '',
            company_tax: props.data.company_tax||'',
            port_of_discharge:props.editShipping.port_of_discharge || '',
            default_address:props.editShipping.default_address?props.editShipping.default_address:!props.shippingaddress.length?"Y":"N",
            name_atten:props.editShipping.name_atten || '',
            id:props.editShipping.id || '',
        })
       } 
       setFormkKey(formKey+1)
    },[props.data,props.editShipping,props.shippingaddress])
    const Schema = Yup.object().shape({
        name_atten: Yup.string("Enter Atten Name").required("Atten Name is required"),
        shipping_address_1: Yup.string("Enter Address 1").required("Address 1 is required"),
        shipping_email: Yup.string()
            .email("Wrong email format")
            .required("Email is required"),
        shipping_city: Yup.string("Enter City").required("City is required"),
        country: Yup.string("Enter Country").required("Country is required"),
        shipping_state: Yup.string("Enter State").required("Pincode is required"),
        shipping_zip_code: Yup.string("Enter zip code").required("zip code is required"),
        shipping_phone: Yup.string("Enter Telphone").required("Telephone is required"),
        company_tax: Yup.string("Company Tax/GST Id number").required("Company Tax/GST Id is required"),
        port_of_discharge: Yup.string("Enter Port Of Discharge").required("Port Of Discharge is required"),
    });
    const enableLoading = () => {
        // setLoading(true);
        setOpenLoader(true);
    };
    const disableLoading = () => {
        setOpenLoader(false);
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            enableLoading()
            axios({
                method: "POST",
                url: `${NODE_API_URL}/AUS/iu-shipping-address`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get("cust_auth_token")}`
                },
                data:{
                    ...values,
                    default_address:isChecked?"Y":values.default_address
                }
            }).then((res) => {
                // console.log(res.data,"resres")
                if(res && res.data){
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear()
                        window.location.reload(true)
                    }
                    disableLoading()
                    props.setMount(!props.mount)
                    props.setShowEditShipping(false)
                    props.setEditShipping({})
                    formik.resetForm()
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
            
        }
    });   
    
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
      setIsChecked(e.target.checked);
    };
    useEffect(() => {
        if(props.editShipping.default_address === "Y"){
            setIsChecked(true)
        }
    },[props.editShipping.default_address])
    console.log(isChecked,"isCheckedisChecked")
    return (
        <div>
           <Backdrop className={classes.backdrop} open={openLoader} >
                <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
            </Backdrop>
            <div
                className="row"
            >
                <div className="font-size-h3  pb-5 text-center col-12 font-weight-bold">
                    {props.status} Shipping Address
                </div>
                <hr className="w-25 my-auto mb-5" />
                <div className="col-12 pb-0 mt-8">
                    <FormikProvider key={formKey} value={formik}>
                        <form onSubmit={formik.handleSubmit}> 
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    
                                    <TextField
                                        fullWidth
                                        label="Company Name *"
                                        variant="outlined"
                                        name="company_name_official"
                                        InputLabelProps={{
                                            shrink: props.data ? true : false ,
                                          }}
                                        {...formik.getFieldProps("company_name_official")}
                                        error={formik.touched.company_name_official && Boolean(formik.errors.company_name_official)}
                                        helperText={formik.touched.company_name_official && formik.errors.company_name_official}
                                        readOnly
                                        disabled
                                    />
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="name_atten"
                                            className="bg-white"
                                            shrink={formik.values.name_atten || formik.touched.name_atten}
                                            error={formik.touched.name_atten && Boolean(formik.errors.name_atten)}
                                        >
                                            Atten Name *
                                        </InputLabel>
                                        <TextField
                                            id="name_atten"
                                            name="name_atten"
                                            value={formik.values.name_atten}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.name_atten && Boolean(formik.errors.name_atten)}
                                            helperText={formik.touched.name_atten && formik.errors.name_atten}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    {/* <TextField
                                        fullWidth
                                        label="Atten Name *"
                                        variant="outlined"
                                        name="name_atten"
                                        InputLabelProps={{
                                            shrink: props.data ? true : false ,
                                          }}
                                        {...formik.getFieldProps("name_atten")}
                                        error={formik.touched.name_atten && Boolean(formik.errors.name_atten)}
                                        helperText={formik.touched.name_atten && formik.errors.name_atten}
                                    /> */}
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_address_1"
                                            className="bg-white"
                                            shrink={formik.values.shipping_address_1 || formik.touched.shipping_address_1}
                                            error={formik.touched.shipping_address_1 && Boolean(formik.errors.shipping_address_1)}
                                        >
                                            Address line 1 *
                                        </InputLabel>
                                        <TextField
                                            id="shipping_address_1"
                                            name="shipping_address_1"
                                            value={formik.values.shipping_address_1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_address_1 && Boolean(formik.errors.shipping_address_1)}
                                            helperText={formik.touched.shipping_address_1 && formik.errors.shipping_address_1}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_address_2"
                                            className="bg-white"
                                            shrink={formik.values.shipping_address_2 || formik.touched.shipping_address_2}
                                            error={formik.touched.shipping_address_2 && Boolean(formik.errors.shipping_address_2)}
                                        >
                                            Address line 2
                                        </InputLabel>
                                        <TextField
                                            id="shipping_address_2"
                                            name="shipping_address_2"
                                            value={formik.values.shipping_address_2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_address_2 && Boolean(formik.errors.shipping_address_2)}
                                            helperText={formik.touched.shipping_address_2 && formik.errors.shipping_address_2}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_address_3"
                                            className="bg-white"
                                            shrink={formik.values.shipping_address_3 || formik.touched.shipping_address_3}
                                            error={formik.touched.shipping_address_3 && Boolean(formik.errors.shipping_address_3)}
                                        >
                                            Address line 3
                                        </InputLabel>
                                        <TextField
                                            id="shipping_address_3"
                                            name="shipping_address_3"
                                            value={formik.values.shipping_address_3}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_address_3 && Boolean(formik.errors.shipping_address_3)}
                                            helperText={formik.touched.shipping_address_3 && formik.errors.shipping_address_3}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_city"
                                            className="bg-white"
                                            shrink={formik.values.shipping_city || formik.touched.shipping_city}
                                            error={formik.touched.shipping_city && Boolean(formik.errors.shipping_city)}
                                        >
                                            City *
                                        </InputLabel>
                                        <TextField
                                            id="shipping_city"
                                            name="shipping_city"
                                            value={formik.values.shipping_city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_city && Boolean(formik.errors.shipping_city)}
                                            helperText={formik.touched.shipping_city && formik.errors.shipping_city}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_state"
                                            className="bg-white"
                                            shrink={formik.values.shipping_state || formik.touched.shipping_state}
                                            error={formik.touched.shipping_state && Boolean(formik.errors.shipping_state)}
                                        >
                                            State *
                                        </InputLabel>
                                        <TextField
                                            id="shipping_state"
                                            name="shipping_state"
                                            value={formik.values.shipping_state}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_state && Boolean(formik.errors.shipping_state)}
                                            helperText={formik.touched.shipping_state && formik.errors.shipping_state}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_zip_code"
                                            className="bg-white"
                                            shrink={formik.values.shipping_zip_code || formik.touched.shipping_zip_code}
                                            error={formik.touched.shipping_zip_code && Boolean(formik.errors.shipping_zip_code)}
                                        >
                                            Pin ode *
                                        </InputLabel>
                                        <TextField
                                            id="shipping_zip_code"
                                            name="shipping_zip_code"
                                            value={formik.values.shipping_zip_code}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_zip_code && Boolean(formik.errors.shipping_zip_code)}
                                            helperText={formik.touched.shipping_zip_code && formik.errors.shipping_zip_code}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="country"
                                            className="bg-white"
                                            shrink={formik.values.country || formik.touched.country}
                                            error={formik.touched.country && Boolean(formik.errors.country)}
                                        >
                                            Country *
                                        </InputLabel>
                                        <TextField
                                            id="country"
                                            name="country"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.country && Boolean(formik.errors.country)}
                                            helperText={formik.touched.country && formik.errors.country}
                                            variant="outlined"
                                            disabled
                                            readOnly
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="shipping_email"
                                            className="bg-white"
                                            shrink={formik.values.shipping_email || formik.touched.shipping_email}
                                            error={formik.touched.shipping_email && Boolean(formik.errors.shipping_email)}
                                        >
                                            Email *
                                        </InputLabel>
                                        <TextField
                                            id="shipping_email"
                                            name="shipping_email"
                                            value={formik.values.shipping_email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.shipping_email && Boolean(formik.errors.shipping_email)}
                                            helperText={formik.touched.shipping_email && formik.errors.shipping_email}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6 shipping-add">
                                    <PhoneInputFieldFull
                                        label="Telephone"
                                        name="shipping_phone"
                                        country="in" // Set the default country code (e.g., India)
                                        placeholder="Please Enter Shipping TelePhone"
                                        required
                                    />
                                    
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="company_tax"
                                            className="bg-white"
                                            disabled
                                            readOnly
                                            shrink={formik.values.company_tax || formik.touched.company_tax}
                                            error={formik.touched.company_tax && Boolean(formik.errors.company_tax)}
                                        >
                                            Company Tax/GST Id *
                                        </InputLabel>
                                        <TextField
                                            id="company_tax"
                                            name="company_tax"
                                            value={formik.values.company_tax}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.company_tax && Boolean(formik.errors.company_tax)}
                                            helperText={formik.touched.company_tax && formik.errors.company_tax}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-md-7 mt-6">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel
                                            htmlFor="port_of_discharge"
                                            className="bg-white"
                                            shrink={formik.values.port_of_discharge || formik.touched.port_of_discharge}
                                            error={formik.touched.port_of_discharge && Boolean(formik.errors.port_of_discharge)}
                                        >
                                            Port Of Discharge *
                                        </InputLabel>
                                        <TextField
                                            id="port_of_discharge"
                                            name="port_of_discharge"
                                            value={formik.values.port_of_discharge}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.port_of_discharge && Boolean(formik.errors.port_of_discharge)}
                                            helperText={formik.touched.port_of_discharge && formik.errors.port_of_discharge}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </form>
                    </FormikProvider>
                </div>
                <div className="col-12 d-flex pt-5 sticky-top justify-content-between bg-white" style={{bottom:"0"}}>
                    <div className="checkbox-list">
                        <label className="checkbox">
                            <input type="checkbox" 
                            checked={isChecked}
                            onChange={handleCheckboxChange}/>
                            <span></span>
                            Set As Default
                        </label>
                    </div>
                    <div>
                        <button onClick={formik.handleSubmit} type="submit" className="btn btn-primary px-8 mr-2">Save</button>
                        <Button className="px-5"
                            variant="secondary"
                            onClick={() => {props.setShowEditShipping(false);props.setEditShipping({});setIsChecked(false)}}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};


import React ,{useState} from 'react';
import { makeStyles , CircularProgress,Backdrop,Snackbar,SnackbarContent} from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';
import red from "material-ui/colors/red";
import clsx from "clsx";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { NODE_API_URL } from '../../../../../../env_config';
const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    boxShadow:'none!important',
    backgroundColor: '#F9FAFF',
    border:'solid 2px #E0E7FF',
    height: '36px',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontWeight: 500,
    color : "#8798AD"
  },
  iconButton: {
    padding: 5,
    color : "#8798AD"
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  error: {
    backgroundColor: red[600],
  },
});

export function Searchbarchurneddashboard(props) {
  // const classes = useStyles();
  const [search,setSearch] = useState()
  const [openLoader, setOpenLoader] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const history = useHistory();

  function handleClose(event, reason) {
    setAlertOpen(false);
}
  const enableLoading = () => {
    // setLoading(true);
    setOpenLoader(true);
    setIsSubmitting(true)
  };

  const disableLoading = () => {
    // setLoading(false);
    setOpenLoader(false);
    setIsSubmitting(false)
  };
  function MySnackbarContentWrapper(props) {
    const classes = useStyles();
    const { className, message, onClose, variant, ...other } = props;

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {message}
                </span>
            }
            {...other}
        />
    );
}
  const SearchDiamond = (value) => {
    enableLoading();
    if(search){
      const userData = JSON.parse(localStorage.getItem("userData"))
      const finalData = {
        "Stock_id":search.replaceAll("N","").replaceAll("L","").replaceAll("-",""),
        "Certi_NO":search.replaceAll("N","").replaceAll("L","").replaceAll("-",""),
        "CustomerId":userData.CustomerId
      }
      axios({
        method: "POST",
        url: `${NODE_API_URL}/AUS/churnedCXSearchByIdOrCerti`,
        headers: {
            "Content-Type": "application/json",
        },
        data: finalData,
      })
      .then(function(res) {
        if(res.data.msg === "No Record Found"){
          disableLoading();
          // alert(res.data.msg)
          setAlertOpen(true);
          setSearch('')
          localStorage.setItem("stoneCount",0)
        }
        else{
          disableLoading();
          localStorage.setItem("stoneCount",res.data.data.length)
          history.push({
            pathname: "/search-result",
            state: {
                searchresult:res.data.data,
                oldvalues:null
            },
                // state: decompress(res.data.ProductDetails),
            });
          setSearch('')
        }
      })
      .catch(function(res) {
        disableLoading();
      });
    }
  }
  return (
    <>
        {/* <InputBase
          className={`${classes.input} ${isSubmitting ? "cursor-na" : ""}`}
          placeholder={props.language.SEARCHBAR_PLACEHOLDER}
          inputProps={{ 'aria-label': 'Search by Certificate Number  or Stock ID' }}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => e.keyCode === 13?SearchDiamond():""}
          value={search}
        />
        <IconButton className={`${classes.iconButton} ${isSubmitting ? "cursor-na" : ""}`}aria-label="Search" onClick={SearchDiamond} disabled={isSubmitting}>
          <SearchIcon/>
        </IconButton> */}
        <div class="input-group rounded">
          <input 
          type="search" 
          class="form-control rounded" 
          aria-label="Search" 
          aria-describedby="search-addon" 
          placeholder={"Search by Certificate Number/StockID/LoatID"}
          inputProps={{ 'aria-label': 'Search by Certificate Number  or Stock ID' }}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => e.keyCode === 13?SearchDiamond():""}
          value={search}
          />
          <span class="input-group-text border-0" id="search-addon" style={{cursor:"pointer"}}>
            <i class="fas fa-search" onClick={SearchDiamond} disabled={isSubmitting}></i>
          </span>
        </div>
        <Backdrop open={openLoader} >
          <CircularProgress color="secondary" />
        </Backdrop>
      
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant="error"
          message={`No Record Found`}
        />
      </Snackbar>
    </>
  );
}